import {
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
  GET_ALL_PARTNERS_REQUEST,
  GET_ALL_PARTNERS_SUCCESS,
  GET_ALL_PARTNERS_FAILURE,
  GET_RESULT_REQUEST,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAILURE,
  GET_CURRENT_STATUS_REQUEST,
  GET_CURRENT_STATUS_SUCCESS,
  GET_CURRENT_STATUS_FAILURE,
  START_CALCULATION_REQUEST,
  START_CALCULATION_SUCCESS,
  START_CALCULATION_FAILURE
} from './resultTypes'
import produce from 'immer'

const initialState = {
  employees: undefined,
  employeesError: undefined,
  employeesLoading: false,

  partners: undefined,
  partnersError: undefined,
  partnersLoading: false,

  result: undefined,
  resultError: undefined,
  resultLoading: false,

  status: undefined,
  statusError: undefined,
  statusLoading: false
}

const userActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES_REQUEST:
      return {
        ...state,
        employees: undefined,
        employeesError: undefined,
        employeesLoading: true
      }
    case GET_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        employeesError: undefined,
        employeesLoading: false
      }

    case GET_ALL_EMPLOYEES_FAILURE:
      return {
        ...state,
        employees: undefined,
        employeesError: action.payload,
        employeesLoading: false
      }
    case GET_ALL_PARTNERS_REQUEST:
      return {
        ...state,
        partners: undefined,
        partnersError: undefined,
        partnersLoading: true
      }
    case GET_ALL_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload,
        partnersError: undefined,
        partnersLoading: false
      }
    case GET_ALL_PARTNERS_FAILURE:
      return {
        ...state,
        partners: undefined,
        partnersError: action.payload,
        partnersLoading: false
      }

    case GET_RESULT_REQUEST:
      return {
        ...state
      }
    case GET_RESULT_SUCCESS:
      return {
        ...state
      }
    case GET_RESULT_FAILURE:
      return {
        ...state
      }

    case GET_CURRENT_STATUS_REQUEST:
      return {
        ...state,
        //status: undefined,
        statusError: undefined,
        statusLoading: true
      }
    case GET_CURRENT_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
        statusError: undefined,
        statusLoading: false
      }
    case GET_CURRENT_STATUS_FAILURE:
      return {
        ...state,
        status: undefined,
        statusError: action.payload,
        statusLoading: false
      }

    case START_CALCULATION_REQUEST:
      return {
        ...state
      }
    case START_CALCULATION_SUCCESS:
      const newState = produce(state.status, (draft) => {
        draft.status = 'CALCULATING'
      })
      return {
        ...state,
        status: newState
      }
    case START_CALCULATION_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}

export default userActionReducer
