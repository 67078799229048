import {
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
  GET_ALL_PARTNERS_REQUEST,
  GET_ALL_PARTNERS_SUCCESS,
  GET_ALL_PARTNERS_FAILURE,
  GET_RESULT_REQUEST,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAILURE,
  GET_CURRENT_STATUS_REQUEST,
  GET_CURRENT_STATUS_SUCCESS,
  GET_CURRENT_STATUS_FAILURE,
  START_CALCULATION_REQUEST,
  START_CALCULATION_SUCCESS,
  START_CALCULATION_FAILURE,
  PUBLISH_RESULTS
} from './resultTypes'
import axios from 'axios'
import { SECRET, URL_TO_API } from '../../Helper/constants'

export const readAllEmployeeRequest = () => {
  return {
    type: GET_ALL_EMPLOYEES_REQUEST
  }
}
export const readAllEmployeeSuccess = (employees) => {
  return {
    type: GET_ALL_EMPLOYEES_SUCCESS,
    payload: employees
  }
}
export const readAllEmployeeFailure = (error) => {
  return {
    type: GET_ALL_EMPLOYEES_FAILURE,
    payload: error
  }
}
export const readAllPartnersRequest = () => {
  return {
    type: GET_ALL_PARTNERS_REQUEST
  }
}
export const readAllPartnersSuccess = (partners) => {
  return {
    type: GET_ALL_PARTNERS_SUCCESS,
    payload: partners
  }
}
export const readAllPartnersFailure = (error) => {
  return {
    type: GET_ALL_PARTNERS_FAILURE,
    payload: error
  }
}
export const readResultRequest = () => {
  return {
    type: GET_RESULT_REQUEST
  }
}
export const readResultSuccess = (result) => {
  return {
    type: GET_RESULT_SUCCESS,
    payload: result
  }
}
export const readResultFailure = (error) => {
  return {
    type: GET_RESULT_FAILURE,
    payload: error
  }
}
export const getCurrentStatusRequest = () => {
  return {
    type: GET_CURRENT_STATUS_REQUEST
  }
}
export const getCurrentStatusSuccess = (status) => {
  return {
    type: GET_CURRENT_STATUS_SUCCESS,
    payload: status
  }
}
export const getCurrentStatusFailure = (error) => {
  return {
    type: GET_CURRENT_STATUS_FAILURE,
    payload: error
  }
}
export const startCalculationRequest = () => {
  return {
    type: START_CALCULATION_REQUEST
  }
}
export const startCalculationSuccess = () => {
  return {
    type: START_CALCULATION_SUCCESS
  }
}
export const startCalculationFailure = (error) => {
  return {
    type: START_CALCULATION_FAILURE,
    payload: error
  }
}

//FUNCTIONS with side effects
export const getAllEmployees = () => {
  return (dispatch) => {
    dispatch(readAllEmployeeRequest())
    axios({
      method: 'get',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/employees'
    })
      .then((response) => {
        const employees = response.data.employees
        dispatch(readAllEmployeeSuccess(employees))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(readAllEmployeeFailure(errorMsg))
      })
  }
}

export const getAllPartners = () => {
  return (dispatch) => {
    dispatch(readAllPartnersRequest())
    axios({
      method: 'get',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/partners'
    })
      .then((response) => {
        const partners = response.data.partners
        dispatch(readAllPartnersSuccess(partners))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(readAllPartnersFailure(errorMsg))
      })
  }
}

export const getResultPartners = () => {
  return (dispatch) => {
    dispatch(readResultRequest())
    axios({
      method: 'get',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/partners'
    })
      .then((response) => {
        const partners = response.data.partners
        dispatch(readResultSuccess(partners))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(readResultFailure(errorMsg))
      })
  }
}

export const getCurrentStatus = () => {
  return (dispatch) => {
    dispatch(getCurrentStatusRequest())
    axios({
      method: 'get',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/status'
    })
      .then((response) => {
        const status = response.data
        dispatch(getCurrentStatusSuccess(status))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(getCurrentStatusFailure(errorMsg))
      })
  }
}

export const startCalculationStatus = () => {
  return (dispatch) => {
    dispatch(startCalculationRequest())
    axios({
      method: 'post',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/calculate'
    })
      .then((response) => {
        //if http ok then accept
        dispatch(startCalculationSuccess())
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(startCalculationFailure(errorMsg))
      })
  }
}

export const publishAllResults = () => {
  return (dispatch) => {
    axios({
      method: 'post',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/publish_results'
    })
      .then((response) => {
        //do nothing with the response
      })
      .catch((error) => {
        const errorMsg = error.message
      })
  }
}
