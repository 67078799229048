import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import createEncryptor from 'redux-persist-transform-encrypt'
import storageSession from 'redux-persist/lib/storage/session'
import resultReducer from './result/resultReducer'

const encryptor = createEncryptor({
  secretKey: '3P2e9BK6WrYmVh9U'
})

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['result'],
  transforms: [encryptor]
}

const rootReducer = combineReducers({
  result: resultReducer
})

export default persistReducer(persistConfig, rootReducer)
