import React, { useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import TextField from '@material-ui/core/TextField'

const ConfirmDialog = (props) => {
  const { visible, onCancel, onConfirm } = props
  const [errorCode, setErrorCode] = useState(false)
  const [msgErrorCode, setMsgErrorCode] = useState('')
  let refInput = useRef(null)

  function testInputAndHandleAction() {
    if (refInput.current.value === 'supergeheim') {
      onConfirm()
      resetValues()
    } else {
      setErrorCode(true)
      setMsgErrorCode('Bitte den Freischaltcode eingeben!')
    }
  }

  function resetValues() {
    clearErrorCode()
    refInput.current.value = ''
  }

  function resetAndCancel() {
    resetValues()
    onCancel()
  }

  const renderFooter = () => {
    return (
      <div>
        <button
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          onClick={resetAndCancel}
          className="senden">
          Abbrechen
        </button>
        <button
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          onClick={testInputAndHandleAction}
          className="senden">
          Berechnung starten
        </button>
      </div>
    )
  }

  const renderPayload = () => {
    return (
      <p>
        Mit dem Start der Berechnung werden alle nicht eingegangenen Bewertungen zufällig gesetzt sowie eine Änderung
        der Stimmabgabe unmöglich.
      </p>
    )
  }

  const clearErrorCode = () => {
    setErrorCode(false)
    setMsgErrorCode('')
  }

  return (
    <Dialog
      header="Möchten Sie die Berechnung jetzt starten?"
      visible={visible}
      style={{ width: '30vw' }}
      onHide={(e) => onCancel(e)}
      footer={renderFooter()}>
      {renderPayload()}
      <TextField
        label="Freischaltcode"
        inputRef={refInput}
        error={errorCode}
        fullWidth={true}
        type="password"
        color="secondary"
        onChange={clearErrorCode}
        helperText={errorCode && msgErrorCode}
      />
    </Dialog>
  )
}

export default ConfirmDialog
