export const GET_ALL_EMPLOYEES_REQUEST = 'GET_ALL_EMPLOYEES_REQUEST'
export const GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS'
export const GET_ALL_EMPLOYEES_FAILURE = 'GET_ALL_EMPLOYEES_FAILURE'

export const GET_ALL_PARTNERS_REQUEST = 'GET_ALL_PARTNERS_REQUEST'
export const GET_ALL_PARTNERS_SUCCESS = 'GET_ALL_PARTNERS_SUCCESS'
export const GET_ALL_PARTNERS_FAILURE = 'GET_ALL_PARTNERS_FAILURE'

export const GET_RESULT_REQUEST = 'GET_RESULT_REQUEST'
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS'
export const GET_RESULT_FAILURE = 'GET_RESULT_FAILURE'

export const GET_CURRENT_STATUS_REQUEST = 'GET_CURRENT_STATUS_REQUEST'
export const GET_CURRENT_STATUS_SUCCESS = 'GET_CURRENT_STATUS_SUCCESS'
export const GET_CURRENT_STATUS_FAILURE = 'GET_CURRENT_STATUS_FAILURE'

export const START_CALCULATION_REQUEST = 'START_CALCULATION_REQUEST'
export const START_CALCULATION_SUCCESS = 'START_CALCULATION_SUCCESS'
export const START_CALCULATION_FAILURE = 'START_CALCULATION_FAILURE'

export const PUBLISH_RESULTS = 'PUBLISH_RESULTS'
