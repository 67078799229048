import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useInterval } from '../../Helper/useInterval'
import {
  startCalculationStatus,
  getCurrentStatus,
  getResultPartners,
  getAllPartners,
  getAllEmployees,
  publishAllResults
} from '../../redux/result/resultActions'
import ConfirmDialog from '../Components/ConfirmDialog'
import Result from '../Components/Result'
import _ from 'lodash'

function WaitingPage(props) {
  const min = 15
  const max = 50
  let animPerson = [0, 1, 2, 3, 4, 5]

  const bin1 = useRef(null)
  const bin2 = useRef(null)
  const bin3 = useRef(null)
  const bin4 = useRef(null)
  const bin5 = useRef(null)
  const bin6 = useRef(null)
  const bin7 = useRef(null)
  const bin8 = useRef(null)
  const bin9 = useRef(null)
  const bin10 = useRef(null)

  const bin1Delay = calcDelays()
  const bin2Delay = calcDelays()
  const bin3Delay = calcDelays()
  const bin4Delay = calcDelays()
  const bin5Delay = calcDelays()
  const bin6Delay = calcDelays()
  const bin7Delay = calcDelays()
  const bin8Delay = calcDelays()
  const bin9Delay = calcDelays()
  const bin10Delay = calcDelays()

  const bin1Order = _.shuffle(animPerson)
  const bin2Order = _.shuffle(animPerson)
  const bin3Order = _.shuffle(animPerson)
  const bin4Order = _.shuffle(animPerson)
  const bin5Order = _.shuffle(animPerson)
  const bin6Order = _.shuffle(animPerson)
  const bin7Order = _.shuffle(animPerson)
  const bin8Order = _.shuffle(animPerson)
  const bin9Order = _.shuffle(animPerson)
  const bin10Order = _.shuffle(animPerson)

  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const [publishedToClients, setPublishedToClients] = useState(false)
  //init all partners and employees in state (only called once)
  useEffect(() => {
    props.getAllPartners()
    props.getAllEmployees()
    props.callCurrentSystemStatus()
  }, [])

  //call the getResult each 5 seconds
  useInterval(() => {
    if (props.calcState !== 'READY') {
      props.callCurrentSystemStatus()
    }
  }, 5000)

  //increase the counter each 1s
  useInterval(() => {
    if (
      bin1 &&
      bin1.current &&
      bin2 &&
      bin2.current &&
      bin3 &&
      bin3.current &&
      bin4 &&
      bin4.current &&
      bin5 &&
      bin5.current &&
      bin6 &&
      bin6.current &&
      bin7 &&
      bin7.current &&
      bin8 &&
      bin8.current &&
      bin9 &&
      bin9.current && 
      bin10 &&
      bin10.current
    ) {
      bin1.current.increaseTimer()
      bin2.current.increaseTimer()
      bin3.current.increaseTimer()
      bin4.current.increaseTimer()
      bin5.current.increaseTimer()
      bin6.current.increaseTimer()
      bin7.current.increaseTimer()
      bin8.current.increaseTimer()
      bin9.current.increaseTimer()
      bin10.current.increaseTimer()
      if (
        !publishedToClients &&
        bin1.current.allEntriesVisible() &&
        bin2.current.allEntriesVisible() &&
        bin3.current.allEntriesVisible() &&
        bin4.current.allEntriesVisible() &&
        bin5.current.allEntriesVisible() &&
        bin6.current.allEntriesVisible() &&
        bin7.current.allEntriesVisible() &&
        bin8.current.allEntriesVisible() &&
        bin9.current.allEntriesVisible() &&
        bin10.current.allEntriesVisible()
      ) {
        props.showResultAtClients()
        setPublishedToClients(true)
      }
    }
  }, 1000)

  const injectDialog = () => {
    return (
      <ConfirmDialog
        visible={confirmDialogVisible}
        onCancel={(e) => {
          {
            setConfirmDialogVisible(false)
          }
        }}
        onConfirm={(e) => {
          {
            setConfirmDialogVisible(false)
            props.callStartCalculation()
          }
        }}
      />
    )
  }

  function showConfirmDialog() {
    setConfirmDialogVisible(true)
  }

  const waitingSite = () => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <p style={{ textAlign: 'center' }}>
            <span class="porange"> {props.noOfVotes} </span>
            <span class="pwhite"> von </span>
            <span class="porange"> {props.maxVotes} </span>
          </p>
          <p style={{ textAlign: 'center' }}>
            <span class="pwhite"> ABSTIMMUNGEN </span>
          </p>
          <p style={{ textAlign: 'center' }}>
            <ProgressSpinner />
          </p>
          <p style={{ textAlign: 'center' }}>
            <button
              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              onClick={showConfirmDialog}
              className="senden">
              Berechnung starten
            </button>
          </p>
        </div>
      </div>
    )
  }

  const calculatingSite = () => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <p style={{ textAlign: 'center' }}>
            <span class="porange">ALPHA</span>
            <span class="pwhite">Partnerchoice</span>
          </p>
          <p>Berechnung wurde gestartet - Bitte haben Sie einen Moment Geduld.</p>
          <div style={{ textAlign: 'center' }}>
            <ProgressSpinner />
          </div>
        </div>
      </div>
    )
  }

  function calcDelays() {
    return [
      randomInteger(min, max),
      randomInteger(min, max),
      randomInteger(min, max),
      randomInteger(min, max),
      randomInteger(min, max),
      randomInteger(min, max)
    ]
  }

  function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const resultSite = () => {
    const partners = []
    props.allPartners.map((p) => {
      partners.push({ imageUrl: p.imageUrl, name: p.name, id: p.id })
    })
    return (<>
      <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
        {partners.length > 0 ? (
          <Result
            partner={partners[0]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin1}
            delays={bin1Delay}
            order={bin1Order}
          />
        ) : null}
        {partners.length > 1 ? (
          <Result
            partner={partners[1]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin2}
            delays={bin2Delay}
            order={bin2Order}
          />
        ) : null}
        {partners.length > 2 ? (
          <Result
            partner={partners[2]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin3}
            delays={bin3Delay}
            order={bin3Order}
          />
        ) : null}
        {partners.length > 3 ? (
          <Result
            partner={partners[3]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin4}
            delays={bin4Delay}
            order={bin4Order}
          />
        ) : null}
      </div>
      <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
      
          {partners.length > 4 ? (
          <Result
            partner={partners[4]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin5}
            delays={bin5Delay}
            order={bin5Order}
          />
        ) : null}
        <div
          className="card"
          style={{
            backgroundColor: 'rgba(10, 10, 10, 0.7)',
            display: 'grid',
            margin: 'auto'
          }}>
          <p style={{ textAlign: 'center' }}>
            <span class="porange" style={{ fontSize: '1.3em' }}>
              ALPHA
            </span>
            <span class="pwhite" style={{ fontSize: '1.3em' }}>
              PARTNERCHOICE
            </span>
            <br />
            <span class="pwhite" style={{ fontSize: '2em' }}>
              2024
            </span>
          </p>
        </div>
        {partners.length > 5 ? (
          <Result
            partner={partners[5]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin6}
            delays={bin6Delay}
            order={bin6Order}
          />
        ) : null}
      </div>
      <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
        {partners.length > 6 ? (
          <Result
            partner={partners[6]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin7}
            delays={bin7Delay}
            order={bin7Order}
          />
        ) : null}
        {partners.length > 7 ? (
          <Result
            partner={partners[7]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin8}
            delays={bin8Delay}
            order={bin8Order}
          />
        ) : null}
        {partners.length > 8 ? (
          <Result
            partner={partners[8]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin9}
            delays={bin9Delay}
            order={bin9Order}
          />
        ) : null}
        {partners.length > 9 ? (
          <Result
            partner={partners[9]}
            result={props.result}
            employees={props.allEmployees}
            ref={bin10}
            delays={bin10Delay}
            order={bin10Order}
          />
    
        ) : null}  </div> 
      </>
    )
  }

  return (
    <div>
      {injectDialog()}
      {props.calcState === 'READY' || props.calcState === 'PUBLIC'
        ? resultSite()
        : props.calcState === 'CALCULATING'
        ? calculatingSite()
        : waitingSite()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allPartners: state.result.partners ? state.result.partners : [],
    allEmployees: state.result.employees ? state.result.employees : [],
    result: state.result.status && state.result.status.result ? state.result.status.result.data : [],
    noOfVotes: state.result.status ? state.result.status.receivedResults : 0,
    maxVotes: state.result.status ? state.result.status.maxResults : 0,
    calcState: state.result.status ? state.result.status.status : 'WAITING'
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    callCurrentSystemStatus: () => dispatch(getCurrentStatus()),
    callStartCalculation: () => dispatch(startCalculationStatus()),
    getAllPartners: () => dispatch(getAllPartners()),
    getAllEmployees: () => dispatch(getAllEmployees()),
    getResultPartners: () => dispatch(getResultPartners()),
    showResultAtClients: () => dispatch(publishAllResults())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingPage)
