import React, { useState, forwardRef, useImperativeHandle } from 'react'
import _ from 'lodash'
import Fade from '@material-ui/core/Fade'

const Result = forwardRef((props, ref) => {
  const { partner, employees, result, delays, order } = props
  const [counter, setCounter] = useState(0)
  const [showP1, setShowP1] = useState(false)
  const [showP2, setShowP2] = useState(false)
  const [showP3, setShowP3] = useState(false)
  const [showP4, setShowP4] = useState(false)
  const [showP5, setShowP5] = useState(false)
  const [showP6, setShowP6] = useState(false)
  let delayP1 = delays && delays.length > 0 ? delays[0] : 0
  let delayP2 = delays && delays.length > 1 ? delays[1] : 0
  let delayP3 = delays && delays.length > 2 ? delays[2] : 0
  let delayP4 = delays && delays.length > 3 ? delays[3] : 0
  let delayP5 = delays && delays.length > 4 ? delays[4] : 0
  let delayP6 = delays && delays.length > 5 ? delays[5] : 0

  useImperativeHandle(ref, () => ({
    increaseTimer,
    allEntriesVisible
  }))

  const allEntriesVisible = () => {
    if (
      showP1 === true &&
      showP2 === true &&
      showP3 === true &&
      showP4 === true &&
      showP5 === true &&
      showP5 === showP6
    ) {
      return true
    } else {
      return false
    }
  }

  const increaseTimer = () => {
    let timer = counter + 1
    setCounter(timer)
    setShowP1(timer > delayP1)
    setShowP2(timer > delayP2)
    setShowP3(timer > delayP3)
    setShowP4(timer > delayP4)
    setShowP5(timer > delayP5)
    setShowP6(timer > delayP6)
    return timer
  }

  //prepare the result to show for partner
  const data = []
  if (result) {
    result[partner.id].map((m) => {
      employees.map((e) => {
        if (m === e.id) {
          data.push(e)
        }
      })
    })
  }

  const createPartnerSnippet = () => {
    return (
     /*  <Fade
        in={true}
        timeout={{
          appear: 5000,
          enter: 5000
        }}> */
          <div className="partner-img-container">
          <img
           class="circularpic"
            src={process.env.PUBLIC_URL + '/assets/img/' + partner.imageUrl}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
          />
        {/*   <p style={{ textAlign: 'center' }}>
            <span class="porange" style={{ fontSize: '1em' }}>
              {partner.name}
            </span>
          </p> */}
        </div>
     /*  </Fade> */
    )
  }

  const createEmployeeSnippet = (imgUrl, firstname, lastname, showPx) => {
    return (
      <Fade
        in={
          showPx == 0
            ? showP1
            : showPx == 1
            ? showP2
            : showPx == 2
            ? showP3
            : showPx == 3
            ? showP4
            : showPx == 4
            ? showP5
            : showPx == 5
            ? showP6
            : true
        }
        timeout={{
          appear: 1000,
          enter: 1000
        }}>
        <div className="employee-img-container">
          <img
            src={process.env.PUBLIC_URL + '/assets/img/' + imgUrl}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', }}
          />
          <p style={{ textAlign: 'center' }}>
            <div class="pwhite" style={{ fontSize: '1em' }}>
              {firstname}{' '}
            </div>
            <div class="porange" style={{ fontSize: '1em' }}>
              {lastname}{' '}
            </div>
          </p>
        </div>
      </Fade>
    )
  }

  const injectEmployees = () => {
    let answer = []
    for (var i = 0; i < data.length; i++) {
      const e = data[i]
      const p = order[i]
      answer.push(createEmployeeSnippet(e.imageUrl, e.firstname, e.lastname, p))
    }
    return answer
  }

  return (
    <div
      className="card"
      style={{
        backgroundColor: 'rgba(10, 10, 10, 0.7)',
        display: 'grid',
        marginLeft: '15px',
        marginRight: '15px'
      }}>
      <Fade
        in={true}
        timeout={{
          appear: 5000,
          enter: 5000
        }}>
        <p style={{ textAlign: 'center', display:"inline-flex",alignItems:"center" ,marginBottom:"40px" }}>
         {/*  <span class="porange" style={{ fontSize: '1.3em' }}>
            TEAM:{' '}
          </span> */}
          {createPartnerSnippet()}
          <span class="pwhite" style={{ fontSize: '1.3em' }}>
            {_.upperCase(partner.name)}
          </span>
        </p>
      </Fade>
      {
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)'
          }}>
          
          {injectEmployees()}
        </div>
      }
    </div>
  )
})

export default Result
